<template>
  <div>
    <form
      class="divider-gray-200 mt-12 divide-y overflow-hidden bg-white shadow sm:rounded-lg"
      @submit.prevent="onSubmit"
    >
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-md font-semibold leading-6 text-gray-900">
          Ganti Email
        </h3>
      </div>
      <div>
        <dl class="sm:divide-y sm:divide-gray-200">
          <div
            class="flex items-center py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6"
          >
            <dt class="text-sm font-bold text-gray-700">Email Baru</dt>
            <dd
              class="relative mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
            >
              <base-input
                fullwidth
                :shadow="false"
                type="email"
                required
                placeholder="Masukkan Email Baru"
                v-model="email"
              />
            </dd>
          </div>
        </dl>
      </div>
      <div class="flex justify-end px-4 py-5 sm:px-6">
        <base-button>Update</base-button>
      </div>
    </form>

    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  props: {
    userId: String,
  },
  data() {
    return {
      loading: false,
      email: null,
    };
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async onSubmit() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/users/${this.userId}/-actions/update-email`,
        {
          method: 'patch',
          data: {
            email: this.email,
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.email = null;

        this.createAlert({
          status: 'success',
          data: 'Email berhasil diubah',
        });

        this.$emit('success')
      }

      this.loading = false;
    },
  },
};
</script>
