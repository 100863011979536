<template>
  <header class="bg-white p-2 text-gray-700 text-center">
    <div class="flex items-center justify-center flex-wrap text-sm text-center gap-x-2">
      <p>Periode <b class="text-gray-900">{{ user.current_period.name }}</b></p>
      <p class="text-xs text-gray-500 italic">({{ user.current_period.start_date | formatDate('LL') }} - {{ user.current_period.end_date | formatDate('LL') }})</p>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    })
  }
}
</script>