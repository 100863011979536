<template>
  <div
    class="relative inline-block rounded-full p-1 text-left"
    onMouseOver="this.style.backgroundColor='#11111122'"
    onMouseOut="this.style.backgroundColor='transparent'"
    v-click-outside="handleClickOutside"
  >
    <div>
      <button
        type="button"
        class="flex h-8 w-8 items-center justify-center rounded-full text-white"
        @click="handleDropdown"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
        <span class="sr-only">Notifications</span>
        <div
          v-if="getNotifications.meta.page.total"
          class="absolute -top-2 -right-2 inline-flex h-5 w-5 items-center justify-center rounded-full bg-red-500 text-xs font-bold text-white dark:border-gray-900"
        >
          {{ getNotifications.meta.page.total }}
        </div>
      </button>
    </div>
    <div
      v-if="visible"
      class="focus:outline-none absolute right-0 z-10 mt-2 w-80 md:w-96 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
    >
      <div role="none">
        <div
          style="border-radius: 0.375rem 0.375rem 0 0"
          class="border-b bg-gray-100 px-4 py-4 text-sm font-semibold text-gray-800"
        >
          Notifikasi
        </div>
        <p
          v-if="!getNotifications.data.length"
          class="p-4 text-xs text-gray-700"
        >
          Tidak Ada Notifikasi
        </p>
        <template v-else>
          <div
            v-for="notification in getNotifications.data"
            :key="notification.id"
            href="#"
            class="flex items-center justify-between px-4 py-2 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            tabindex="-1"
            id="menu-item-0"
          >
            <div>
              <span class="text-xs text-gray-500">{{
                notification.attributes.createdAt | formatDate
              }}</span>
              <router-link
                :to="notification.attributes.url"
                class="block text-sm text-gray-700"
                >{{ notification.attributes.message }}</router-link
              >
            </div>
            <button v-on:click="handleRead(notification.id)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-green-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    ...mapGetters({
      getNotifications: 'notification/getNotifications',
      getUser: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      fetchNotifications: 'notification/fetchNotifications',
      updateNotifications: 'notification/updateNotifications',
    }),
    ...mapMutations({
      pushNotification: 'notification/pushNotification',
      editNotification: 'notification/editNotification',
    }),
    handleDropdown() {
      this.visible = !this.visible;
      this.$emit('click', this.visible);
    },
    handleClickOutside() {
      this.visible = false;
    },
    async handleRead(notificationUuid) {
      await this.updateNotifications({
        id: notificationUuid,
        payload: {
          data: {
            type: 'notifications',
            id: notificationUuid,
            attributes: {
              readAt: new Date(),
            },
          },
        },
      });

      this.loadNotifications();
    },
    loadNotifications() {
      if (!this.getNotifications.jsonapi) {
        this.fetchNotifications({
          params: {
            'page[size]': 10,
            'page[number]': 1,
            sort: '-createdAt',
            'filter[is_read]': false,
          },
        });
      }
    },
  },
  created() {
    this.loadNotifications();

    const userChannel = this.$pusher.subscribe(
      `private-App.Models.User.${this.getUser.id}`
    );

    userChannel.bind('NotificationCreated', (e) => {
      this.pushNotification({
        id: e.model.uuid,
        attributes: e.model,
      });
    });
    userChannel.bind('NotificationUpdated', (e) => {
      this.editNotification({
        id: e.model.uuid,
        attributes: e.model,
      });
    });
  },
};
</script>
