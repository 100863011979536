const LayoutDefault = () =>
  import(/* webpackChunkName: "mitra-usaha" */ '@/layouts/LayoutDefault.vue');

const KomisiBulanan = () =>
  import(
    /* webpackChunkName: "mitra-usaha" */ '@/views/member/komisi-bulanan/Index.vue'
  );

let root = '/komisi-bulanan';

export default [
  {
    path: root + '/',
    component: KomisiBulanan,
    meta: {
      auth: true,
      title: 'KomisiBulanan',
      layout: LayoutDefault,
    },
  },
];
