<template>
    <div :class="['mx-auto px-4 sm:px-4 lg:px-8', marginVertical ? 'my-4' : '']">
        <slot />

        <loading v-if="loading" />
    </div>
</template>

<script>
export default {
    props: {
        loading: Boolean,
        marginVertical: {
            type: Boolean,
            default: true
        }
    }
}
</script>