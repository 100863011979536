const LayoutDefault = () =>
  import(/* webpackChunkName: "mitra-usaha" */ '@/layouts/LayoutDefault.vue');

const Cashbill = () =>
  import(
    /* webpackChunkName: "mitra-usaha" */ '@/views/member/cashbill/Index.vue'
  );

let root = '/cashbill';

export default [
  {
    path: root + '/',
    component: Cashbill,
    meta: {
      auth: true,
      title: 'Cashbill',
      layout: LayoutDefault,
    },
  }
];
