<template>
  <div class="flex min-h-screen bg-white">
    <div class="relative hidden w-0 flex-1 bg-gray-50 lg:flex">
      <div class="flex w-full items-center justify-center p-8">
        <ForgotIllustration class="object-cover" alt="Login Illustrator" />
      </div>
    </div>
    <div
      class="relative flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-10"
    >
      <div class="absolute top-0 py-12">
        <base-button color="light" size="sm" :to="{ name: 'Login' }">
          <!-- Heroicon name: solid/mail -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M7 16l-4-4m0 0l4-4m-4 4h18"
            />
          </svg>
          Kembali
        </base-button>
      </div>
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div class="flex flex-col">
          <div class="flex justify-center">
            <NasaLogo class="h-24" alt="Workflow" />
          </div>
          <h2 class="mt-6 text-xl font-extrabold text-gray-900">
            Lupa Password
          </h2>
          <p class="mt-2 text-sm leading-6 text-gray-600">
            Masukan Kode Kantor / ID NASA dan Username / Email terdaftar maka kami akan mengirimkan instruksi untuk mengganti password melalui email.
          </p>
        </div>

        <div class="mt-6">
          <form class="space-y-6" @submit.prevent="onSubmit">
            <alert-message v-if="error" type="error" :message="error" />
            <alert-message v-if="success" type="success" :message="success" />

            <base-input
              type="text"
              fullwidth
              :shadow="false"
              placeholder="Contoh : N-1 / ND-101 / NE-621 / AB.001 / SC / KP / PUSAT"
              label="Kode Kantor / ID NASA"
              :invalid="!!validationError.code"
              :message="validationError.code"
              v-model="code"
            />
            <base-input
              type="text"
              fullwidth
              :shadow="false"
              placeholder="Contoh : mitrausahanasa@gmail.com"
              label="Username / Email"
              :invalid="!!validationError.email"
              :message="validationError.email"
              v-model="email"
            />

            <base-button fullwidth :loading="loading">Kirim</base-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ForgotIllustration, NasaLogo } from '@/assets/images';
import AlertMessage from '@/components/base/AlertMessage.vue';
import { requestMixin } from '@/mixins/request/request';
import { validationMixin } from '@/mixins/validation/validation.mixin';
import { z } from 'zod';

export default {
  name: 'ForgotPassword',
  mixins: [requestMixin, validationMixin],
  components: {
    ForgotIllustration,
    NasaLogo,
    AlertMessage,
  },
  data() {
    return {
      code: null,
      email: null,
      error: null,
      validationError: {
        code: null
      },
      success: null,
      loading: false,
    };
  },
  computed: {
    schema() {
      return z.object({
        code: z.string({ required_error: 'Kode tidak boleh kosong', invalid_type_error: 'Kode tidak boleh kosong' }).min(1, 'Kode tidak boleh kosong'),
        email: z.string({ required_error: 'Email tidak boleh kosong', invalid_type_error: 'Email tidak boleh kosong' }).email('Email tidak valid')
      })
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      this.validationError = {}
      this.error = null;
      this.success = null;

      const [data, validationError] = await this.validate(this.schema, {
        code: this.code,
        email: this.email
      })

      if (validationError) {
        this.validationError = { ...validationError }
      } else {
        const [, requestErr] = await this.request('/api/v1/password/forgot', {
          method: 'post',
          data
        });

        if (requestErr) {
          this.error = this.getRequestErrorMessage(requestErr);
        } else {
          this.success = 'Link reset password sudah dikirim ke email anda';
        }
      }

      this.loading = false;
    },
  },
};
</script>
