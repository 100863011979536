<template>
  <main
    class="flex flex-grow"
  >
    <loading v-if="isLoading" />
    <div class="flex-grow flex flex-col sm:justify-center py-8 sm:space-y-12 sm:p-0" v-else-if="getMenus.data?.length > 0">
      <div class="text-center sm:space-y-4">
        <p class="hidden text-2xl font-bold sm:flex items-center justify-center gap-x-2">
          {{ me.current_role }}
          <svg v-if="me.is_pbs_activated" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 w-6 text-green-600" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
          </svg>
        </p>
        <p class="text-gray-600"><span class="font-bold sm:font-normal text-gray-900 sm:text-gray-600">{{ me.office_code }}</span> - {{ me.name }}</p>
        <p class="text-xs sm:text-sm text-gray-500 italic sm:not-italic">(Wilayah Harga {{ getWilayah }})</p>
      </div>
      <div
        class="flex-grow sm:flex-grow-0 flex flex-col justify-center max-w-2xl mx-auto px-4"
        id="menu-available"
      >
        <template v-if="role === 'Stockist'">
          <StockistMenu />
        </template>
        <div
          class="grid gap-4"
          :class="totalMenu > 2 ? 'grid-cols-2 md:grid-cols-3' : 'grid-cols-2'"
        >
          <div v-for="(menu, index) in getMenus.data.filter(
            (it) => it.attributes.name.toLowerCase() !== 'admin' && it.attributes.parent_id === null
          )" :key="index">
            <button
              @click="setMenu(menu)"
              class="group flex w-full h-full cursor-pointer flex-col justify-center items-center rounded-lg border border-gray-200 bg-white px-6 py-4 transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-110"
            >
              <div class="relative w-max">
                <img
                  class="h-20 w-20"
                  :src="getPath(menu) + '.gif'"
                  :alt="'Gambar ' + menu.attributes.name"
                  loading="lazy"
                />
              </div>
              <div class="relative">
                <span
                  class="relative block text-center text-base font-semibold text-blue-900 group-hover:text-blue-500 dark:text-white"
                  >{{ menu.attributes.name }}</span
                >
                <p v-if="menu.attributes.name === 'Komisi Bulanan'" class="text-center text-sm font-bold text-gray-900">
                  {{ me.current_office_commission | toCurrency }}
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { StorageService } from '@/services/storage.service';
import StockistMenu from '@/components/stockist/stockist-menu.vue';

export default {
  components: {
    StockistMenu,
  },
  data() {
    const role = StorageService.getRole();
    return {
      isLoading: false,
      baseUrl: process.env.VUE_APP_API_URL,
      role,
    };
  },
  computed: {
    ...mapGetters({
      getMenusByOfficeCategory: 'office_categories/getMenusByOfficeCategory',
      getRolesByUser: 'users/getRolesByUser',
      getMenus: 'menus/getMenus',
      me: 'auth/getUser',
    }),
    getWilayah() {
      const list = ['Jawa', 'I', 'II', 'III', 'IV', 'loading...'];
      return list[this.me.office_area_code ?? 5];
    },
    totalMenu() {
      return this.getMenus.data.filter((it) => !it.attributes.parent_id).length
    }
  },
  methods: {
    ...mapActions({
      fetchMenus: 'menus/fetchMenus',
    }),
    ...mapMutations({
      authSetMenu: 'auth/setMenu',
    }),
    setMenu(menu, to) {
      this.authSetMenu(menu);

      const menus = {
        Superadmin: '/superadmin',
        Admin: '/admin',
        Gudang: '/gudang',
        Kasir: '/kasir',
        Penjualan: '/penjualan',
        Pembelian: '/pembelian',
        Pelayanan: '/kantor-pelayanan',
        'Barang Masuk': '/barang-masuk',
        'Transaksi Penjualan': '/pengiriman-barang',
        'Retur Barang': '/retur-barang',
        Laporan: '/laporan',
        'Cash Bill': '/cashbill',
        'Cashbill': '/cashbill',
        'Komisi Bulanan': '/komisi-bulanan',
        Bonus: '/bonus',
        'Stock Stockist': '/stock-stockist',
      };

      if (Object.prototype.hasOwnProperty.call(menus, menu.attributes.name)) {
        this.$router.push(to || menus[menu.attributes.name]);
      }
    },
    searchMenu(menu1) {
      return this.getMenusByOfficeCategory.data.some(
        (menu) => menu.attributes.name === menu1.name
      );
    },
    getPath(menu) {
      let filePath = `${this.baseUrl}${menu.attributes.icon}`;
      return filePath.lastIndexOf('.svg') !== -1
        ? filePath.substring(0, filePath.lastIndexOf('.svg'))
        : `${filePath}`;
    }
  },
};
</script>
