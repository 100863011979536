<template>
  <div class="border-b border-gray-200 pb-4 mb-4">
    <h3 class="text-base font-semibold leading-6 text-gray-900">Akses Cepat</h3>
    <div class="mt-2 grid sm:grid-cols-2 lg:grid-cols-4 gap-2">
      <button
        @click="setMenu('Gudang', '/gudang/pembelian/tambah-pembelian')"
        class="w-full focus:outline-none inline-flex items-center gap-2 rounded-md border border-transparent bg-green-600 px-3 py-2 text-left text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
      >
        <!-- Heroicon name: solid/mail -->
        <AddBarang class="h-6 w-6 flex-shrink-0" />
        Order Barang
      </button>
      <template v-if="!nueraCode">
        <div class="tooltip">
          <button
            @click="setMenu('Admin', 'admin/daftar-member/tambah-member')"
            class="w-full focus:outline-none inline-flex items-center gap-2 rounded-md border border-transparent bg-green-600 px-3 py-2 text-left text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-600 disabled:focus:ring-gray-500"
          >
            <!-- Heroicon name: solid/mail -->
            <AddUser class="h-6 w-6 flex-shrink-0" />

            Pencatatan Mitra Usaha
          </button>
        </div>
        <button
          @click="
            setMenu(
              'Cash Bill',
              '/cashbill/transaksi-cashbill/tambah-transaksi-cash-bill'
            )
          "
          class="w-full focus:outline-none inline-flex items-center gap-2 rounded-md border border-transparent bg-green-600 px-3 py-2 text-left text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
        >
          <!-- Heroicon name: solid/mail -->
          <AddMoney class="h-6 w-6 flex-shrink-0" />
          Pencatatan Cash Bill
        </button>
        <div class="tooltip">
          <button
            @click="
              setMenu('Cash Bill', '/cashbill/pencatatan-tambah-poin/tambah-poin')
            "
            :disabled="!me.is_bonus_period"
            class="w-full focus:outline-none inline-flex items-center gap-2 rounded-md border border-transparent bg-green-600 px-3 py-2 text-left text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-600 disabled:focus:ring-gray-500"
          >
            <!-- Heroicon name: solid/mail -->
            <AddMoney class="h-6 w-6 flex-shrink-0" />
            Tambah Point
            <span
              v-if="!me.is_bonus_period"
              style="width: 200px"
              class="tooltiptext w-20"
              >Tidak dapat menambah poin karena bukan period tutup buku</span
            >
          </button>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import { StorageService } from '@/services/storage.service';
import { AddUser, AddBarang, AddMoney } from '@/assets/icons';

export default {
  components: {
    AddUser,
    AddBarang,
    AddMoney,
  },
  data() {
    const role = StorageService.getRole();
    return {
      isLoading: true,
      baseUrl: process.env.VUE_APP_API_URL,
      role,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
      getMenus: 'menus/getMenus',
    }),
    nueraCode() {
      return this.me.office_code.toLowerCase() == 'nuera';
    },
  },
  methods: {
    ...mapMutations({
      authSetMenu: 'auth/setMenu',
    }),
    setMenu(menuName, to) {
      const menu = this.getMenus.data.find((it) =>
        it.attributes.name.includes(menuName)
      );
      this.authSetMenu(menu);

      const menus = {
        Superadmin: '/superadmin',
        Admin: '/admin',
        Gudang: '/gudang',
        Kasir: '/kasir',
        Penjualan: '/penjualan',
        Pembelian: '/pembelian',
        Pelayanan: '/kantor-pelayanan',
        'Barang Masuk': '/barang-masuk',
        'Transaksi Penjualan': '/pengiriman-barang',
        'Retur Barang': '/retur-barang',
        Laporan: '/laporan',
        'Cash Bill': '/cashbill',
        Bonus: '/bonus',
        'Stock Stockist': '/stock-stockist',
      };

      if (Object.prototype.hasOwnProperty.call(menus, menu.attributes.name)) {
        this.$router.push(to || menus[menu.attributes.name]);
      }
    },
  },
};
</script>
