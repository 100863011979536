<template>
  <div>
    <form
      class="divider-gray-200 mt-12 divide-y overflow-hidden bg-white shadow sm:rounded-lg"
      @submit.prevent="onSubmit"
    >
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-md font-semibold leading-6 text-gray-900">
          Ganti Password
        </h3>
      </div>
      <div>
        <dl class="sm:divide-y sm:divide-gray-200">
          <div
            class="flex items-center py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6"
          >
            <dt class="text-sm font-bold text-gray-700">Kata sandi lama</dt>
            <dd
              class="relative mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
            >
              <svg
                class="absolute inset-y-0 top-1 z-10 flex h-7 w-7 cursor-pointer items-center pl-3"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18 8C18 11.3137 15.3137 14 12 14C11.3938 14 10.8087 13.9101 10.2571 13.7429L8 16H6V18H2V14L6.25707 9.74293C6.08989 9.19135 6 8.60617 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8ZM12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6C13.1046 6 14 6.89543 14 8C14 8.55228 14.4477 9 15 9C15.5523 9 16 8.55228 16 8C16 5.79086 14.2091 4 12 4Z"
                  fill="#9CA3AF"
                />
              </svg>
              <base-input
                fullwidth
                :shadow="false"
                type="password"
                :classes="{ input: 'pl-9' }"
                required
                v-model="currentPassword"
              />
            </dd>
          </div>
          <div
            class="flex items-center py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6"
          >
            <dt class="text-sm font-bold text-gray-700">Kata sandi baru</dt>
            <dd
              class="relative mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
            >
              <svg
                class="absolute inset-y-0 top-1 z-10 flex h-7 w-7 cursor-pointer items-center pl-3"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18 8C18 11.3137 15.3137 14 12 14C11.3938 14 10.8087 13.9101 10.2571 13.7429L8 16H6V18H2V14L6.25707 9.74293C6.08989 9.19135 6 8.60617 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8ZM12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6C13.1046 6 14 6.89543 14 8C14 8.55228 14.4477 9 15 9C15.5523 9 16 8.55228 16 8C16 5.79086 14.2091 4 12 4Z"
                  fill="#9CA3AF"
                />
              </svg>
              <base-input
                fullwidth
                :shadow="false"
                type="password"
                :classes="{ input: 'pl-9' }"
                required
                v-model="newPassword"
              />
            </dd>
          </div>
          <div
            class="flex items-center py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6"
          >
            <dt class="text-sm font-bold text-gray-700">
              Konfrimasi sandi baru
            </dt>
            <dd
              class="relative mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
            >
              <svg
                class="absolute inset-y-0 top-1 z-10 flex h-7 w-7 cursor-pointer items-center pl-3"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18 8C18 11.3137 15.3137 14 12 14C11.3938 14 10.8087 13.9101 10.2571 13.7429L8 16H6V18H2V14L6.25707 9.74293C6.08989 9.19135 6 8.60617 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8ZM12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6C13.1046 6 14 6.89543 14 8C14 8.55228 14.4477 9 15 9C15.5523 9 16 8.55228 16 8C16 5.79086 14.2091 4 12 4Z"
                  fill="#9CA3AF"
                />
              </svg>
              <base-input
                fullwidth
                :shadow="false"
                type="password"
                :classes="{ input: 'pl-9' }"
                required
                v-model="newPasswordConfirmation"
              />
            </dd>
          </div>
        </dl>
      </div>
      <div class="flex justify-end px-4 py-5 sm:px-6">
        <base-button>Update</base-button>
      </div>
    </form>

    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  props: {
    userId: String,
  },
  data() {
    return {
      loading: false,
      currentPassword: null,
      newPassword: null,
      newPasswordConfirmation: null,
    };
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async onSubmit() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/users/${this.userId}/-actions/update-password`,
        {
          method: 'patch',
          data: {
            current_password: this.currentPassword,
            new_password: this.newPassword,
            new_password_confirmation: this.newPasswordConfirmation,
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.currentPassword = null;
        this.newPassword = null;
        this.newPasswordConfirmation = null;

        this.createAlert({
          status: 'success',
          data: 'Kata sandi berhasil diubah',
        });
      }

      this.loading = false;
    },
  },
};
</script>
