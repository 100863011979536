<template>
  <base-wrapper class="no-print">
    <nav class="flex" aria-label="Breadcrumb">
      <ol role="list" class="flex items-center space-x-4">
        <li>
          <div class="flex">
            <router-link
              :to="
                '/' +
                (home_link?.attributes?.url ? home_link.attributes.url.startsWith('/') ? home_link.attributes.url.slice(1) : home_link.attributes.url : '')
              "
              class="inline-flex items-center text-sm text-gray-700 hover:text-green-600 dark:text-gray-400 dark:hover:text-white"
            >
              <svg
                class="mr-2 h-4 w-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                ></path>
              </svg>
              Home
            </router-link>
          </div>
        </li>
        <li v-for="(breadcrumb, idx) in breadCrumbs" :key="idx">
          <div class="flex items-center">
            <svg
              class="h-6 w-6 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <router-link
              :to="breadcrumb.href"
              class="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 dark:text-gray-400 dark:hover:text-white md:ml-2"
              >{{ breadcrumb.label }}</router-link
            >
          </div>
        </li>
      </ol>
    </nav>
    <div class="mt-2">
      <h2
        class="mt-2 text-lg font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl"
        v-if="checkHeading()"
      >
        {{ text_heading }}
      </h2>
    </div>
  </base-wrapper>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Breadcrumb',
  data() {
    return {
      text_heading: '',
      breadcrumbList: [],
    };
  },
  computed: {
    ...mapGetters({
      home_link: 'auth/getMenu',
    }),
    /**
     * @see https://medium.com/@pratheekhegde/displaying-application-breadcrumbs-in-vue-js-85456dc8a370
     */
    breadCrumbs() {
      let pathArray = this.$route.path.split('/');
      pathArray.shift();
      const breadCrumbs = [];
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = '';
      for (let i = 0; i < pathArray.length; ++i) {
        breadcrumb = `${breadcrumb}${'/'}${pathArray[i]}`;
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], 'meta') &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, 'breadcrumb')
        ) {
          breadCrumbs.push({
            href: this.$route.matched[i].path,
            disabled: i + 1 === pathArray.length,
            label: this.$route.matched[i].meta.breadcrumb || pathArray[i],
          });
          breadcrumb = '';
        }
      }
      return breadCrumbs;
    },
  },
  methods: {
    checkHeading() {
      if (this.$route.meta.title) {
        let dashboard = this.$route.meta.title.split(' | ');
        this.text_heading = dashboard[0];
        return this.text_heading;
      }
    },
  },
};
</script>
